/* Publications.css */

.publications {
    text-align: center;
    padding: 20px;
}

.publications h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.publications ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.publications li {
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    max-width: 200px;
}

.publications img {
    width: 100%;
    height: auto;
}

.publications p {
    padding: 10px;
    background-color: #f8f8f8;
}

.publications h2 {
    font-size: 2.5rem;
    /* Larger font size */
    color: #4a90e2;
    /* Choose a color that fits your site's theme */
    margin-bottom: 30px;
    /* Space below the heading */
    text-align: center;
    /* Center the heading */
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    position: relative;
    display: inline-block;
    /* Aligns the pseudo-elements correctly */
}

.publications h2:after,
.publications h2:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 50%;
    /* Width of the underline */
    background: #4a90e2;
    /* Same as text color */
    bottom: -10px;
    /* Position below the text */
}

.publications h2:before {
    width: 20%;
    /* Smaller line width */
    bottom: -15px;
    /* Position further below */
    background: #f8e71c;
    /* A contrasting color */
}

/* Optionally, add a hover effect */
.publications h2:hover {
    color: #db4a39;
    /* Change color on hover */
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}