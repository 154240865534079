/* Header.css */
.header {
    position: fixed;
    /* Fix the position of the header */
    padding-bottom: 45px;
    top: 0;
    /* Align it to the top of the viewport */
    left: 0;
    /* Align it to the left of the viewport */
    width: 100%;
    /* Ensure it spans the full width */
    z-index: 1000;
    /* Ensure it stays on top of other elements */
    background-color: #1a1a1a;
    /* Dark background for the header */
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 35px;
    /* Adjust this value to reduce the size */
    width: auto;
    /* This will maintain the aspect ratio of the logo */
}

.navigation {
    display: flex;
}

.nav-item {
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 0 15px;
    font-size: 1.2em;
    font-weight: bold;
}

.nav-item:hover,
.nav-item:focus {
    text-decoration: underline;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 600px) {

    .navigation {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        position: absolute;
        right: 0;
        top: 5px;
        width: 100%;
        text-align: left;
    }

    .header-content {
        flex-direction: column;
    }

    .logo {
        margin-bottom: 5px;
        display: none;
    }

    .navigation.active {
        display: flex;
    }

    .nav-item {
        color: rgb(187, 214, 9);
        font-size: 0.9em;
        flex-basis: 0%;
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        box-sizing: content-box;
    }

    .hamburger-menu {
        display: block;
        cursor: pointer;
    }
}

.navigation:after {
    content: "";
    display: table;
    clear: both;
}