.contact-container {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.contact-container h1 {
    text-align: center;
    color: #0077b5;
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 1.1rem;
    color: #333;
    font-weight: bold;
}

.contact-container input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group textarea {
    width: 100%;
    /* Adjust width here */
    max-width: 100%;
    /* Ensure it doesn't exceed its container */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    /* Make the button a block element */
    width: auto;
    /* Adjust button width */
    margin: 0 auto;
    /* Center the button */
}

button[type="submit"]:hover {
    background-color: #45a049;
}

.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
}

input,
textarea {
    margin-bottom: 10px;
    padding: 8px;
}

button {
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
}

.success-message {
    margin-top: 10px;
    color: green;
    font-size: 1rem;
}