/* BlogSection.css */

.blog-section-title {
    text-align: center;
    /* Center the heading */
    font-size: 2.5rem;
    /* Adjust the font size as needed */
    color: #4a4a4a;
    /* Choose a color that fits your site's theme */
    margin-top: 20px;
    margin-bottom: 30px;
    /* Add some space around the heading */
    padding: 10px;
    border-bottom: 3px solid #0077b5;
    /* Add a colored underline */
    display: inline-block;
    /* Needed to apply the border correctly */
    border-radius: 5px;
    /* Optionally round the corners of the underline */
}

/* Add any additional responsive adjustments if necessary */
@media (max-width: 768px) {
    .blog-section-title {
        font-size: 2rem;
        /* Slightly smaller font on smaller screens */
    }
}