/* LinkedInNewsletterFeed.css */

.linkedin-newsletter {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    /* Center the tile */
    color: #333;
    text-align: center;
    /* Center-align the text */
    max-width: 600px;
    /* Set a max-width for better readability */
}

.linkedin-newsletter h2 {
    color: #0077b5;
    margin-bottom: 15px;
}

.linkedin-newsletter ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.linkedin-newsletter li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.linkedin-newsletter a {
    color: #0077b5;
    text-decoration: none;
    transition: color 0.3s ease;
}

.linkedin-newsletter a:hover,
.linkedin-newsletter a:focus {
    color: #0056a3;
}

.libutton {
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
    justify-content: center;
    /* Center content horizontally */
    margin: 0 auto;
    /* Center the button itself horizontally */
    padding: 7px;
    text-align: center;
    text-decoration: none !important;
    color: #ffffff !important;
    width: 200px;
    height: 32px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    background-color: #0A66C2;
    font-family: "SF Pro Text", Helvetica, sans-serif;
}