/* YouTubeVlogsFeed.css */

.youtube-vlogs {
    padding: 20px;
    text-align: center;
    /* Center-align the text and content */
}


.youtube-vlogs h2 {
    font-size: 2.5rem;
    /* Increase font size */
    color: #cc181e;
    /* YouTube's brand red color, adjust as needed */
    margin-bottom: 20px;
    /* Space below the heading */
    text-align: center;
    /* Center the heading */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
    padding-bottom: 10px;
    /* Padding below the text */
    border-bottom: 3px solid #cc181e;
    /* Underline effect */
    display: inline-block;
    /* Required for the underline to work correctly */
    border-radius: 5px;
    /* Rounded corners for the underline */
}

/* Optionally, add a hover effect */
.youtube-vlogs h2:hover {
    color: #0eafd3;
    /* Slightly lighter red on hover */
    border-bottom-color: #e74c3c;
}

.youtube-vlogs ul {
    list-style: none;
    /* Remove default list styling */
    padding: 0;
    display: flex;
    /* Use flexbox for a responsive layout */
    flex-wrap: wrap;
    /* Wrap items to the next line */
    justify-content: center;
    /* Center items horizontally */
    gap: 20px;
    /* Space between items */
}

.youtube-vlogs li {
    flex-basis: calc(25% - 20px);
    /* Four items per row, accounting for the gap */
    max-width: 300px;
    /* Maximum width of each item */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    border-radius: 8px;
    /* Rounded corners */
    overflow: hidden;
    /* Ensure content fits within the borders */
}

.youtube-vlogs img {
    width: 100%;
    /* Full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    border-bottom: 1px solid #eee;
    /* Separator between image and text */
}

.youtube-vlogs p {
    padding: 10px;
    /* Padding around the title */
    margin: 0;
    /* Remove default margin */
    background-color: #f8f8f8;
    /* Light background for the title */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .youtube-vlogs li {
        flex-basis: calc(50% - 20px);
        /* Two items per row on smaller screens */
    }
}

@media (max-width: 480px) {
    .youtube-vlogs li {
        flex-basis: 100%;
        /* Full width on very small screens */
    }
}