/* Introduction.css */
.introduction {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px 20px;
    background-color: #f0f0f0;
}

.intro-text {
    flex: 1;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introduction h1 {
    font-size: 3rem;
    /* Larger font size */
    color: #0077b5;
    /* A striking color - adjust as needed */
    text-align: center;
    /* Center-align the text */
    margin-bottom: 20px;
    /* Space below the heading */

    /* Optional: Text shadow for a subtle effect */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

    /* Optional: Add an animation or transition for interactivity */
    transition: transform 0.3s ease;
}

.introduction h1:hover {
    transform: scale(1.25);
    /* Slightly enlarge the name on hover */
}

.animated-text {
    font-size: 1.5rem;
    /* Font size for animated text */
    color: #333;
    /* Color for animated text */
    opacity: 0;
    /* Start with text invisible */
    animation: fadeInOut 6s linear infinite;
    text-align: center;
    /* Center-align the text */
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    10%,
    30% {
        opacity: 1;
        text: "Role 1";
    }

    40%,
    60% {
        opacity: 1;
        text: "Role 2";
    }

    70%,
    90% {
        opacity: 1;
        text: "Role 3";
    }
}

.animated-text-container {
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    min-height: 2em;
    margin-top: 10px;
    opacity: 0;
    /* Start with text invisible */
    animation: fadeIn 1.5s ease forwards;
    /* Animation to fade in */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.intro-photo {
    flex: 1;
}

.intro-photo img {
    width: 100%;
    max-width: 400px;
    /* Adjust size as needed */
    height: auto;
    border-radius: 10px;
    /* Optional: if you want rounded corners */
}

.libutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7px;
    text-align: center;
    outline: none;
    text-decoration: none !important;
    color: #ffffff !important;
    width: 200px;
    height: 32px;
    border-radius: 16px;
    background-color: #0A66C2;
    font-family: "SF Pro Text", Helvetica, sans-serif;
    margin-top: 20px;
    /* Adjust spacing as needed */
    /* Existing styles */
    margin-top: 20px;
    /* Center align the button */
    display: inline-block;
    /* Aligns the button inline */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .introduction {
        flex-direction: column;
        text-align: center;
    }

    .intro-text {
        padding-right: 0;
        padding-bottom: 20px;
    }
}