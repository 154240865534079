/* Footer.css */
.footer {
    background-color: #1a1a1a;
    /* Dark background for the footer */
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-content {
    margin-bottom: 10px;
}

.footer-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
}

.footer-link:hover,
.footer-link:focus {
    text-decoration: underline;
}

.copy-right {
    font-size: 0.8em;
}