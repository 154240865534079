/* MediumBlogsFeed.css */

.medium-blogs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Four tiles per row */
    gap: 20px;
    /* Spacing between tiles */
    padding: 20px;
    width: 100%;
}

.medium-blogs-heading {
    text-align: center;
    font-size: 2em;
    /* Larger font size */
    color: #0077b5;
    /* Medium's brand color, or choose your own */
    padding: 10px 0;
    margin-bottom: 30px;
    /* Space below the heading */
    border-bottom: 3px solid #0077b5;
    /* Underline effect */
    display: inline-block;
    /* Required for the underline effect */
    border-radius: 5px;
    /* Rounded corners for the underline */
}

/* Optionally, add a hover effect */
.medium-blogs-heading:hover {
    color: #e74c3c;
    /* Slightly lighter red on hover */
    border-bottom-color: #e74c3c;
}

.tile {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-decoration: none;
    color: inherit;
}

.tile h3,
.tile p {
    margin: 0;
    /* Resetting default margins */
}

.tile img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .medium-blogs {
        grid-template-columns: repeat(3, 1fr);
        /* Three tiles per row for medium screens */
    }
}

@media (max-width: 768px) {
    .medium-blogs {
        grid-template-columns: repeat(2, 1fr);
        /* Two tiles per row for smaller screens */
    }
}

@media (max-width: 480px) {
    .medium-blogs {
        grid-template-columns: 1fr;
        /* One tile per row for very small screens */
    }

    .medium-blogs-heading {
        font-size: 1.5em;
        /* Slightly smaller font on smaller screens */
    }
}